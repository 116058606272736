/* General Menu Styles */
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.menu-link {
  text-decoration: none;
  color: #000;
}

.menu-link:hover {
  text-decoration: none;
}

.icon-container {
  display: flex;
  align-items: center;
  margin-right: 8px;
  transition: transform 0.4s ease; /* Smooth icon transition */
}

.right-chevron {
  transition: transform 0.4s ease;
  transform: rotate(0deg);
}

.up-chevron {
  transition: transform 0.4s ease;
  transform: rotate(-90deg);
  display: none;
}

.icon-container.expanded .right-chevron {
  transform: rotate(90deg);
}

.icon-container.expanded .up-chevron {
  display: inline;
}

/* Smoothly expand/collapse the submenus */
.sub-menu-container {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.4s ease, opacity 0.3s ease;
}

.sub-menu-container.expanded {
  max-height: 100%; /* Adjust to a large enough value */
  opacity: 1;
  transition: max-height 0.4s ease, opacity 0.3s ease;
}

.sub-menu {
  margin-left: 20px;
}
