.course-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  color: #000;
  position: relative; /* Add relative positioning to contain absolute positioned elements */
}

.course-menu li {
  padding: 6px 12px; /* Adjust padding as needed */
  cursor: pointer; /* Indicate hoverability */
  position: relative; /* Add relative positioning */
  white-space: nowrap; /* Prevent text wrapping */
}

.course-menu li a,
.course-menu li span {
  text-decoration: none;
  color: inherit; /* Inherit text color from parent */
}

.course-menu li:hover {
  background-color: #f5f5f5; /* Light background on hover */
}

.course-menu ul {
  background-color: #fff;
  position: absolute;
  left: 0; /* Align to the left of the parent */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  display: none; /* Hide nested menus by default */
  width: auto; /* Set width to auto fit content */
}

.course-menu li:hover > ul {
  display: block; /* Show nested menu on hover */
}

.course-menu ul li {
  white-space: nowrap; /* Prevent text wrapping */
}
.course-menu ul li > ul {
  position: absolute; /* Position nested menus absolutely */
  top: 0; /* Position below the parent item */
  left: 100%; /* Align to the left of the parent */
  background-color: #fff; /* Background color for nested menus */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  display: none; /* Hide nested menus by default */
}

@media (max-width: 600px) {
  .course-menu {
    flex-direction: column;
    align-items: flex-start;
  }
  .course-menu ul {
    position: relative;
    align-items: flex-start;
  }
  .course-menu ul li > ul {
    position: relative;
    left: 1%;
  }
}
